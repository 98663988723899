// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBJm6Engk--wLUP0fSkBAO9-MeZoxC15-g",
    authDomain: "react-portfolio-cb42d.firebaseapp.com",
    projectId: "react-portfolio-cb42d",
    storageBucket: "react-portfolio-cb42d.appspot.com",
    messagingSenderId: "635753781779",
    appId: "1:635753781779:web:a25d500044a6a973f0bde6",
    measurementId: "G-VE4LBB0FB7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);